import styles from './PaymentOptionsPage.module.css';
import { formatDate } from '../../shared/helpers';
import { useNavigate } from 'react-router-dom';
// import check_mark from './assets/Images/Group 3.svg'

const TransactionCard = ({ transaction }) => {
  const navigate=  useNavigate()
    return (
        <div className={styles.card_wrapper}>
          

            <div>
                <span className={styles.price}>${transaction?.amount}</span>
            </div>

            <div >
                <span className={styles.type_header}>{transaction?.name}</span>
                <span className={styles.type_text}>Powerfull charging flexibility for every adventure</span>
            </div>
            <div className='my-2'>
               
            <div className={styles.package_info_wrapper}>
                <div className='d-flex align-items-center '><img src={'/assets/Images/Group 3.svg'} alt='' className={styles.check_mark}  /><div className={styles.card_text}><span style={{ fontWeight: '500' }} >{transaction?.electric_KW} KW</span> for 30 days</div></div>
                <div className='d-flex align-items-center '><img src={'/assets/Images/Group 3.svg'} alt='' className={styles.check_mark}  /><div className={styles.card_text}><span style={{ fontWeight: '500' }} >Valid until</span> {formatDate(new Date(transaction?.expiration_at), 'date')}
                </div></div>
                <div className='d-flex align-items-center '><img src={'/assets/Images/Group 3.svg'} alt='' className={styles.check_mark}  /><div className={styles.card_text}>priority in busy stations</div></div>
                <div className='d-flex align-items-center '><img src={'/assets/Images/Group 3.svg'} alt='' className={styles.check_mark}  /><div className={styles.card_text}>24/7 customer support</div></div>

            </div>

                <div className={styles.card_item_wrapper}>{transaction?.description}</div>
            </div>

            
        </div>
    );
}

export { TransactionCard };
