// AuthContext.js
import axios from 'axios';
import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const login = async (user) => {
        try {
          const response = await axios.post('https://api.payment.ikaruselectric.com/Admin/admin-login/', {
            username:user?.username,
            password:user.password,
          });
          handleLogin(response.data); 
        } catch (error) {
            handleLogout();
         
        }
      };
    useEffect(() => {
       let user=localStorage.getItem('ikarus-pay-amount-user')
   if(user){
    user= JSON.parse(user);
    login(user)
   }
}, []);
  const [currentUser, setCurrentUser] = useState(null);

  const handleLogin = (user) => {
    setCurrentUser(user);

    localStorage.setItem('ikarus-pay-amount-user', JSON.stringify(user));
  };

  const handleLogout = () => {
    // Clear currentUser and remove user data from localStorage
    setCurrentUser(null);
    localStorage.removeItem('ikarus-pay-amount-user');
  };

  return (
    <AuthContext.Provider value={{ currentUser, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
