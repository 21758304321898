import { Button } from "@mui/material";
import styles from "./PaymentOptionsPage.module.css";
import { useAuth } from "../contexts/auth-context";
import { LoginModal } from "./login-modal/login-modal";
// import Group1 from "../Images/Group1.jpg";
// import Group2 from "../Images/ikarus_logo.svg";

const Header = ({setErrMsg}) => {
  const { currentUser,handleLogout } = useAuth();
// ------------------------------------------------------------------------------------------------
const logout=()=>{
  handleLogout()
}
// ------------------------------------------------------------------------------------------------
return (
    <div className={styles.header_wrapper}>
      <div className={styles.images_wrapper}>
        <div className={`${styles.header_icon} me-2`}>
          <img src={'/assets/images/Group1.jpg'} alt="Image1" />
        </div>
        <div className={styles.header_secondary_icon}>
          <img src={'/assets/images/ikarus_logo.svg'} alt="Image2" />
        </div>
       
      </div>
     {currentUser ?<Button sx={{height:'fit-content'}} color="error" onClick={logout}>logout</Button>:      
           <LoginModal  setErrMsg={setErrMsg} />
}
    </div>
  );
};

export { Header };
