import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import PaymentOptionsPage from "./components/paymentOptionsPage/PaymentOptionsPage";
import PaymentCallbackPage from "./components/paymentCallbackPage/PaymentCallbackPage";
import { Notfound } from "./components/errPages/404";
import { AuthProvider } from "./components/contexts/auth-context";

function App() {
  return (
    <div>
      <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/:stationId" element={<PaymentOptionsPage />} />
          <Route path="/payment-callback-page" element={<PaymentCallbackPage />} />
          <Route path={'*'} element={ <Notfound/>} />  
        </Routes>
      </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
