import { useEffect, useState } from 'react';
import styles from './PaymentOptionsPage.module.css'
import axios from 'axios';
import {  Button, MenuItem, Select } from '@mui/material';
import { TransactionsAcc } from './transactions-acc';
import { useParams } from 'react-router-dom';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

const Transaction = ({ setErrMsg }) => {
    const { stationId } = useParams();
    const [searchText, setSearchText] = useState('');
    const [transactionDetails, setTransactionDetails] = useState();
    const [filteredTransactionDetails, setFilteredTransactionDetails] = useState();
    const [searchBy, setSearchBy] = useState('id');
    const [isExportDisabled, setIsExportDisabled] = useState(false);

    // ------------------------------------------------------------------------------------------------
    const getTransaction = async () => {
        setIsExportDisabled(false)

        setFilteredTransactionDetails( transactionDetails?.filter(el=>{
            if(searchBy==='id'&&searchText){
                return  el?.id?.toString()?.includes(searchText)
            }else if(searchBy==='phone'&&searchText){
                return el?.phone_number
                ?.includes(searchText)
            }else{
                return el
            }
        }))

    }
    // =----------------------------------------------------------------------------------------===
    // const getStations = async () => {
    //     try {
    //         const res = await axios(`https://api.payment.ikaruselectric.com/Transaction/unique-stations/`)
    //         setStations(res.data)
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }
    // =----------------------------------------------------------------------------------------===
    const getStationTransactions = async () => {
        try {
            const res = await axios(`https://api.payment.ikaruselectric.com/Transaction/list-Transaction/?station=${stationId}`,
                {
                    headers: { 'Authorization': 'Token 87b19078d33d000f74d7735d8f4a465158051df17360220fc218ddab8ffb08ea' }
                })
                           setTransactionDetails(res.data);
        } catch (e) {
            if(e?.response?.status===500  ){
                setErrMsg('Internal Server Error');

            }else{
                setErrMsg('Something went wrong,please try again');

            }
        }
    }

    // =----------------------------------------------------------------------------------------===
    useEffect(() => {
        getStationTransactions()
    }, []);
    // =----------------------------------------------------------------------------------------===
    const exportExcl = async (isGeneral=false) => {
        const url=isGeneral?`https://api.payment.ikaruselectric.com/Transaction/report-extract/`:`${`https://api.payment.ikaruselectric.com/Transaction/report-extract/?${isGeneral?'source=general&':'source=station&'}station=${stationId}${searchText?(searchBy==='phone'?'&phone_number=':'&id='):''}${searchText||''}`}`
        // return
        axios({
            url ,
            method: 'GET',
            responseType: 'blob', // Important: Set the response type to blob
        })
            .then((response) => {
                // Create a blob from the response
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);
      
                // Create a link element to trigger the download
                const a = document.createElement('a');
                a.href = url;
                a.download =  `${stationId}-trans-excel-sheet.xlsx`; // Change the file name if needed
                document.body.appendChild(a);
                a.click();
      
                // Cleanup
                window.URL.revokeObjectURL(url);
            })
            .catch((e) => {
                console.log(e);
                if(e?.response?.status===500  ){
                    setErrMsg('Internal Server Error');
    
                }else{
                    setErrMsg('Something went wrong,please try again');
    
                }      
            });
      }
    // =----------------------------------------------------------------------------------------===
    return (
        <div className={styles.pay_as_you_go_wrapper} >
            <div className={`${styles.pay_as_you_go_text_wrapper} w-100 text-light mt-3`}>
                <div className={styles.gradient_text}>Secure Payment Information Input!</div>
                <div className={`${styles.header_title} mt-1`}>Please enter your transaction ID or phone number in the card field to get your transactions details.</div>
            </div>
            <div className={styles.transaction_id_search_form}>
          <div className='w-100 d-lg-flex justify-content-between'>{stationId==='general'&& <Button sx={{fontSize:'.7rem'}} variant='outlined' onClick={()=>exportExcl(true)} ><SaveAltIcon sx={{fontSize:'1rem'}} className='me-2'/>{'Export All Transactions'}</Button>}
             <Button sx={{fontSize:'.7rem'}} className=' mt-2 mt-lg-0' variant='outlined' onClick={()=>exportExcl(false)} ><SaveAltIcon sx={{fontSize:'1rem'}} className='me-2'/>{'Export'}</Button>
             </div>  
                <input className={styles.amount_input} placeholder={searchBy === 'id' ? 'ID' : 'Phone'}
                    value={searchText} onChange={(e) => {
                        setIsExportDisabled(!!e.target.value)
                        setSearchText(e.target.value) }} />

                <Select
                    sx={{ borderRadius: '8px' }}
                    className='w-100' value={searchBy} onChange={(e) => {
                        setSearchBy(e.target.value);
                        setIsExportDisabled(!!e.target.value)
                    }}>
                    <MenuItem value={'id'}>ID</MenuItem>
                    <MenuItem value='phone'>Phone</MenuItem>
                    {/* <MenuItem value='station'>Station</MenuItem> */}
                </Select>
                <button  className={styles.gradient_button} onClick={getTransaction} > {'Search'}</button>

                {transactionDetails && <TransactionsAcc  isExportDisabled={isExportDisabled} filterBy={searchBy} filterText={searchText} transactionDetails={filteredTransactionDetails?filteredTransactionDetails:transactionDetails} />



                }
            </div>

        </div>
    );
}

export { Transaction };
