import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useAuth } from '../../contexts/auth-context';
import userEvent from '@testing-library/user-event';
import { useParams } from 'react-router-dom';

const styles = {
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius:4
    },
    textField: {
      width: '100%',
    },
    button: {
      width: '100%',
    },
  };

const LoginModal = ({ setErrMsg}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { stationId } = useParams();
  const { currentUser } = useAuth();
  const [open,setOpen] = useState(stationId==='general'&&!currentUser?true:false);
  const { handleLogin } = useAuth();
// ------------------------------------------------------------------------------------------------
const login = async () => {
    try {
      const response = await axios.post('https://api.payment.ikaruselectric.com/Admin/admin-login/', {
        username,
        password,
      });
      const user = response.data;
      handleLogin(user); 
      setOpen(false)
    } catch (error) {
        setErrMsg('Login error');
     
    }
  };
 

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };


// ------------------------------------------------------------------------------------------------
useEffect(() => {
if(!currentUser&& stationId==='general'){
setOpen(true)
}
}, [currentUser]);
// ------------------------------------------------------------------------------------------------
return (<div>
          <Button sx={{height:'fit-content',fontWeight:'bold'}} color="inherit" onClick={()=>setOpen(true)}>Login</Button>
    <Modal open={open} onClose={()=>setOpen(false)}>
      <Box sx={styles.modal}>
        <TextField
                size='small'

        className='mb-3'
          label="Username"
          sx={styles.textField}
          value={username}
          onChange={handleUsernameChange}
        />
        <TextField
        size='small'
                className='mb-3'
          label="Password"
          type="password"
          sx={styles.textField}
          value={password}
          onChange={handlePasswordChange}
        />
        <Button
        
          variant="contained"
          color="primary"
          sx={styles.button}
          onClick={login}
          disabled={!userEvent&&!password}
        >
          Login
        </Button>
      </Box>
    </Modal>
    </div>
  );
};

export  {LoginModal};
