
import axios from 'axios';
import styles from './PaymentOptionsPage.module.css'
import { TransactionCard } from './transaction-card';
import { Header } from './header';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Snackbar } from '@mui/material';
import { PaymentSec } from './payment-sec';
import { Transaction } from './transaction';
import { useParams } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';
import { LoginModal } from './login-modal/login-modal';


const PaymentOptionsPage = () => {
    const { currentUser } = useAuth();
    const { stationId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [renderdPage, setRenderdPage] = useState(stationId==='general'?'transaction':'payment');
    const [errMsg, setErrMsg] = useState('');
    const [userTransactions, setUserTransactions] = useState([]);
    // --------------------------------------------------------------------------------------------
    useEffect(() => {
       if(!currentUser&&stationId!=='general'){
             setRenderdPage('payment')
       }else{
        setRenderdPage('transaction')
       }
    }, [currentUser]);
    // --------------------------------------------------------------------------------------------
    return (
        <><div>
            {/*upper_sec*/}
            {isLoading && <CircularProgress className='centered_progress' />}
            <div className={styles.upper_sec}>
                <Header setErrMsg={setErrMsg} setRenderdPage={setRenderdPage}/>
                {currentUser&&stationId!=='general'&&<div className={styles.toggle_buttons_wrapper}>
                    <div
                        style={{ borderRadius: '8px 0 0 8px', paddingRight: '5px' }}
                        className={renderdPage === 'payment' ? styles.toggle_button + ' ' + styles.selected_button : styles.toggle_button}
                        onClick={() => {
                            setRenderdPage('payment')
                        }}>Payment</div>
                    <div

                        style={{ borderRadius: '0 8px 8px 0', paddingLeft: '5px' }}
                        className={renderdPage === 'transaction' ? styles.toggle_button + ' ' + styles.selected_button : styles.toggle_button}
                        onClick={() => {
                            setRenderdPage('transaction')
                        }}>Transaction</div>

                </div>}
                {renderdPage === 'transaction' && !!currentUser && <Transaction setUserTransactions={setUserTransactions} setErrMsg={setErrMsg} />}
                {renderdPage === 'payment'&&stationId!=='general'  && <PaymentSec stationId={stationId} setErrMsg={setErrMsg} />}
            </div>
            {/* <BundlesTitle /> */}

            <div className={styles.cards_wrapper}>
                {renderdPage === 'transaction'  && userTransactions?.map((transaction, i) => {
                    return <TransactionCard key={i} bundle={transaction} />
                })}
            </div>
            <Snackbar
                open={!!errMsg}
                autoHideDuration={2000}
                onClose={() => { setErrMsg('') }}
                message={errMsg}
            />
        </div>
            {/* <Footer/> */}
        </>
    );
}

export default PaymentOptionsPage;
