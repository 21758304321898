import { useEffect } from 'react';
import styles from './PaymentCallbackPage.module.css';
import { useSearchParams } from 'react-router-dom';

const PaymentCallbackPage = () => {
    const [searchParams] = useSearchParams();
    let params = Object.fromEntries(searchParams);
  
    useEffect(() => {
       setTimeout(() => {
        emitMsg()
       }, 4000);
       
    }, []);
    const emitMsg = () => {
        
        window.messageHandler?.postMessage(JSON.stringify({status:params.status,transaction_id:params.transaction_id}));

    }
    return (
        <div className={styles.img_wrapper}>
            <div className={`${styles.suc_msg_wrapper}`}>
                {params.status === 'success' ? <div className={styles.card}>
                    <div style={{ borderRadius: '200px', height: '200px', width: '200px', background: '#F8FAF5', margin: "0 auto" }}>
                        <i style={{ color: '#88b14b' }}>✓</i>
                    </div>
                    <h1 style={{ color: '#88b14b' }} className='  my-4'>Payment Successful</h1>
                    <div className={styles.secondary_text}>Thank you for your payment.
                    <br /> Enjoy Our Services!<br/><br/>

                    <p className={`w-75 mx-auto d-flex justify-content-center flex-wrap ${styles.secondary_text}`} style={{maxWidth:'300px'}}>
                          <span className='w-50 text-start'>Phone Number:</span>   <span  className='w-50 text-end'>{params.phone_number}</span>
                          <span className='w-50 text-start'> ID:</span> <span className='w-50 text-end'> {params.paymob_transaction_id} </span> 
                            
                          <span className='w-50 text-start'> Amount:</span> <span className='w-50 text-end'>{params.amount}</span> 
                            </p>
                            </div>
                    {/* <button className='btn-success btn' onClick={emitMsg}> done</button> */}
                </div> :
                    <div className={styles.card} >
                        <div style={{ borderRadius: '200px', height: '200px', width: '200px', background: '#F8FAF5', margin: "0 auto" }}>
                            <span className='text-danger d-flex justify-content-center align-item-center mt-5' style={{ fontSize: '7rem' }}>x</span>
                        </div>
                        <h1 className='text-danger my-3'>Payment Failed</h1>
                        <p className={styles.secondary_text}>We were unable to process your payment.<br />
                            Please review your payment information</p>
                        {/* <button className='btn-success btn' onClick={emitMsg}>done</button> */}
                    </div>}
            </div>
            {/* {params.isSuccess==='true'?<img className='w-100' src={success_img} alt=''/> 
            : <img className='w-100' src={failure_img} alt=''/>} */}
        </div>
    );
}

export default PaymentCallbackPage;
