import * as React from 'react';
import { styled } from '@mui/material/styles';
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { formatDate } from '../../shared/helpers';
import styles from'./PaymentOptionsPage.module.css'
import { Button } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
export  function TransactionsAcc({transactionDetails,isExportDisabled}) {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
// ------------------------------------------------------------------------------------------------


// ------------------------------------------------------------------------------------------------

  return (
    <div style={{width:'100%'}}>
      {transactionDetails.map((transaction,i)=>{
     return <Accordion key={i} expanded={(expanded === `panel${i+1}`)||true} onChange={handleChange(`panel${i+1}`)}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <div  className={'d-flex justify-content-between align-items-center w-100'} ><Typography>{formatDate(new Date(transaction?.created_at),'date&time')}</Typography>
          {/* {i===0?<Button sx={{color:'#0b2931' ,fontSize:'.9rem'}} className={'d-flex align-items-center '}disabled={isExportDisabled} onClick={exportExcl}><SaveAltIcon className='mx-1' sx={{color:'#0b2931' ,fontSize:'1.1rem'}}/> Export</Button>:''} */}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='w-100 d-flex flex-wrap '>
          <div className={styles.acc_item}>Amount:</div> <div className={styles.acc_item}>{transaction.amount}</div>
          <div className={styles.acc_item}>ID:</div> <div className={styles.acc_item}>{transaction.paymob_transaction_id?transaction.paymob_transaction_id:transaction.id}</div>
          <div className={styles.acc_item}>Status:</div> <div className={`${styles.acc_item} ${transaction.status==='Failed'?'text-danger':transaction.status==='Success'?'text-success':'text-primary'}`}>{transaction.status==='Pending'?'Down':transaction.status}</div>
          {transaction.phone_number?<><div className={styles.acc_item}>Phone Number:</div> <div className={styles.acc_item}>{transaction.phone_number}</div></>:null}
          <div className={styles.acc_item}>Station:</div> <div className={styles.acc_item}>{transaction.station}</div>
          {/* <div className={styles.acc_item}>Amount:</div> <div className={styles.acc_item}>{transaction.id}</div> */}
          </div>
        </AccordionDetails>
      </Accordion>})}
     
    </div>
  );
}
