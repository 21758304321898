import axios from 'axios';
import React, { useState } from 'react';
import styles from './PaymentOptionsPage.module.css'
import { useNavigate } from 'react-router-dom';

const phoneRegex = /^\d{11}$/;

const PaymentSec = ({ setErrMsg, stationId }) => {
    const [paymentAmount, setPaymentAmount] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isAgreedTerms, setIsAgreedTerms] = useState(false);
    const navigate = useNavigate()

    let disablePayButton = isLoading || !isAgreedTerms || !paymentAmount || !phoneRegex.test(phoneNumber)
    const redirectToPaymentPage = async () => {
        const body = {
            "phone_number": phoneNumber,
            "amount": paymentAmount,
            station: stationId
        }
        setIsLoading(true)
        try {
            const res = await axios.post("https://api.payment.ikaruselectric.com/Transaction/get-Transaction-url/", body,
                { headers: { 'Authorization': 'Token 487666615b8aa53f2bb7696705b71a80ae3036a7f6f8e184c9d4bacdd96c9156' } });
            // window.location.href = res.data.url;
            const redirectURL = `/assets?sessionId=${res.data.session_id}`;
            window.location.href = redirectURL;
        } catch (e) {
            setErrMsg('Something went wrong,please try again')
        }
        setIsLoading(false)
    }
    return (
        <div className={styles.pay_as_you_go_wrapper}>
            <div className={`${styles.pay_as_you_go_text_wrapper} w-100 text-light mt-3`}>
                <div className={styles.gradient_text}>Boost Your Charging Power!</div>
                <div className={`${styles.header_title} mt-1`}>Enter the desired amount below to add funds to your account.</div>
            </div>
            <div className={styles.pay_as_you_go_form}>
                <div className={styles.Add_amount_text}>Add amount</div>

                {/* <div className={styles.secondary_text} style={{}}>Staion Id: {stationId}</div> */}
                <div className={styles.secondary_text}>
                    {/* <div>Staion ID: {stationId}</div> */}
                    Prepare for a convenient and efficient charging experience
                </div>
                <input type='number' className={styles.amount_input} placeholder='Phone Number' value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value) }} />
                <input className={styles.amount_input} placeholder='Payment Amount' value={paymentAmount} onChange={(e) => { setPaymentAmount(e.target.value) }} />
                <div>
                    <input checked={isAgreedTerms}
                        style={{ width: '1rem', height: '1rem', marginRight: '.5rem' }}
                        onChange={() => {
                            setIsAgreedTerms(!isAgreedTerms)
                        }} type='checkbox' />
                    I agree to <a href="/assets/terms.pdf" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>

                </div>
                <button disabled={disablePayButton} className={styles.gradient_button} onClick={redirectToPaymentPage} style={{ background: disablePayButton && 'gray' }}> {'Pay Now'}</button>

                <div className='d-flex justify-content-between w-100 pe-2'>
                    <span style={{ fontWeight: '500' }}>Powered By</span>
                    <div className='d-flex justify-content-between '>  <img src='/assets/images/visa.png' alt='' style={{ width: '2rem', margin: '0 8px' }} />
                        <img src='/assets/images/miza.png' alt='' style={{ width: '2rem', margin: '0 8px' }} />
                        <img src='/assets/images/master_card.png' alt='' style={{ width: '2rem', margin: '0 8px' }} /></div>
                </div>
            </div>

        </div>
    );
}


export { PaymentSec };
